<template>
  <base-modal :modal.sync="modal" @changeModal="e => $emit('change-modal', e)">
    <div class="modal-info-entity">
      <div class="modal__topbar">
        <button class="button modal__close">
          <i class="icon icon-close"></i>
        </button>
      </div>

      <div class="modal__content">
        <h1 class="sidebar__title">Avaliar Coletor</h1>
        <h2 class="sidebar__title" style="color:#05b446">Vegetal Coletar</h2>
        <hr />

        <!-- question -->
        <question
          title="O Coletor foi educado?"
          v-model="form.question1"
        ></question>
        <question
          title="O Coletor estava apresentável?"
          v-model="form.question2"
        ></question>
        <question
          title="O Coletor chegou no dia e horário combinado?"
          v-model="form.question3"
        ></question>
        <question
          title="A negociação ficou boa para ambos?"
          v-model="form.question4"
        ></question>
        <question
          title="Você indicaria esse coletor?"
          v-model="form.question5"
        ></question>
        <div class="stars-flex">
          <div><h2 class="sidebar__title">Sua avaliação</h2></div>
          <div>
            <star-rating
              :increment="0.5"
              :star-size="33"
              :padding="5"
              read-only
              :rounded-corners="true"
              v-model="form.nota"
              :show-rating="false"
            ></star-rating>
          </div>
        </div>

        <hr />
        <div class="al">
          <a
            class="button button__medium button__primary"
            href="#"
            title="Finalizar"
            @click.prevent.stop="submit()"
            >Finalizar
          </a>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
import StarRating from 'vue-star-rating'
import { mapGetters } from 'vuex'
import swal from 'sweetalert'

export default {
  data: () => ({
    form: {},
    loadingForm: false
  }),
  methods: {
    async submit () {
      // const url = 'collection/saveRating'
      // corpo
      // {user_id: 7, coletor_id: 6, order_id: 26, resp: [true,true,false,true,true], nota: 4.0}
      if (this.loadingForm) return
      if (
        this.form.question1 === undefined ||
        this.form.question2 === undefined ||
        this.form.question3 === undefined ||
        this.form.question4 === undefined ||
        this.form.question5 === undefined
      ) {
        return swal({
          title: 'Aviso!',
          text: 'Selecione a avaliação.',
          icon: 'error'
        })
      }

      this.loadingForm = true
      try {
        this.form.user_id = this.user.id
        this.form.coletor_id = this.selectedOrder.coletor_id
        this.form.order_id = this.selectedOrder.id
        this.form.resp = [
          this.form.question1,
          this.form.question2,
          this.form.question3,
          this.form.question4,
          this.form.question5
        ]
        const url = 'collection/saveRating'
        const { data, status } = await this.$http.post(url, this.form)
        if (status === 200 && data.status === 'success') {
          swal({
            title: 'Sucesso!',
            icon: 'success',
            text: 'Avaliação realizada com sucesso.',
            buttons: false,
            timer: 2000
          })
          this.$emit('change-modal', false)
          // this.getBalance()
          // this.clearSelected()
          return
        }

        swal({
          title: 'Ooops!',
          icon: 'error',
          text: 'Erro ao realizar avaliação.',
          buttons: false,
          timer: 2000
        })
      } catch (error) {
        swal({
          title: 'Ooops!',
          icon: 'error',
          text: 'Erro ao realizar avaliação.',
          buttons: false,
          timer: 2000
        })
      } finally {
        this.loadingForm = false
      }
    }
  },
  computed: {
    ...mapGetters('user', ['user'])
  },
  props: {
    modal: Boolean,
    selectedOrder: Object
  },

  components: {
    baseModal: () => import('@/components/modal'),
    question: () => import('./questions.vue'),
    StarRating
  },
  watch: {
    form: {
      deep: true,
      handler (val) {
        let nota = 0
        if (val.question1) nota++
        if (val.question2) nota++
        if (val.question3) nota++
        if (val.question4) nota++
        if (val.question5) nota++
        this.form.nota = nota
      }
    }
  }
}
</script>

<style>
.al {
  text-align: center;
}
.modal__content hr {
  margin: 30px 0;
  border-bottom: 1px solid #ccc;
}
.modal__content .stars-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal__content .stars-flex h2 {
  font-size: 18px;
}
.mar-left15 {
  margin-left: 15px;
}
</style>
